import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner';
import CategoryCard from './CategoryCard';
import ProductCard from './ProductCard';

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL
const App = () => {
  const [data, setData] = useState(null);
  const [collection, setCollection] = useState('');
  const [loading, setLoading] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [error, setError] = useState(null);
  const [loc, setLocale] = useState(null);

  // Function to get query parameters
  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  //transform data based on locale
  const transformData = (data, locale, computedShowImage) => {
    const yesValue = locale === 'et' ? 'Jah' : 'Yes';
    const noValue = locale === 'et' ? 'Ei' : 'No';

    // Map over featuresArray and transform featureValue if it's "true" or "false"
    if (data.featuresArray) {
      data.featuresArray = data.featuresArray.map(feature => ({
        ...feature,
        featureValue:
          feature.featureValue === "true" ? yesValue :
          feature.featureValue === "false" ? noValue :
          feature.featureValue
      }));
    }
    data["showImage"]=computedShowImage
    data["factoryName"]=data.category?.value?.factory?.value?.name || data.factory?.value?.name || ""
    data["categoryDesc"]=data.category?.value?.desc || ""
    data["categoryUsp"]=data.category?.value?.uniqueProperties || ""
    return data;
  };

  useEffect(() => {
    const itemId = getQueryParam('itemId');
    const collection = getQueryParam('collection');
    const hideImgParam = getQueryParam('hideImg');
    const computedShowImage = hideImgParam === 'false'; // Compute it before setState
    setShowImage(computedShowImage) 

    const locale = getQueryParam('locale') || 'en'; // english by default

    if (!itemId || !collection) {
      setError('Missing required query parameters.');
      setLoading(false);
      return;
    }

    setCollection(collection);

    const API = `${API_URL}/${collection}/${itemId}?locale=${locale}&draft=true&depth=3`;
    const fetchData = async () => {
      try {
        const response = await axios.get(API, {
          headers: {
            'Authorization': `users API-Key ${API_KEY}`
          }
        });
        const transformedData = transformData(response.data, locale, computedShowImage);
        setData(transformedData)
        setLocale(locale)
        setLoading(false);
      } catch (error) {
        console.log("error: ", error)
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="data-overview">
      {collection.includes('Products') && data && <ProductCard product={data} locale={loc} />}
      {collection.includes('Categories') && data && <CategoryCard category={data} locale={loc}/>}
    </div>

    // <div className="product-overview">
    //   {product && <ProductCard product={product} showImage={showImage} />}
    // </div>
  );
};


export default App;
