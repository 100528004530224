export const isDownloadableFile = (item) => {
    if (!item) return false;

    if (Array.isArray(item)) {
        return item.length > 0 && item[0]?.value?.url;
    }

    return item?.value?.url;
};

export const downloadFile = async (url, name = 'file.pdf') => {
    try {
        const res = await fetch(url, {
            credentials: 'include', // remove if not needed
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        const blob = await res.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
    } catch (err) {
        console.error('Failed to download file:', err);
        alert('Failed to download file');
    }
};

export const renderDownloadLink = (item) => {
    if (!isDownloadableFile(item)) return '-';

    const renderButton = (file, index) => {
        const name = file?.value?.name || file?.value?.filename || `File ${index + 1}`;
        const url = file?.value?.url;
        if (!url) return null;

        return (
            <button
                key={file?.value?.id || index}
                onClick={() => downloadFile(url, name)}
                style={{
                    background: 'none',
                    border: 'none',
                    color: 'blue',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    padding: 0,
                    display: 'block', // Each link on a new line
                    marginBottom: '4px'
                }}
            >
                {name}
            </button>
        );
    };

    if (Array.isArray(item)) {
        return item.map((file, index) => renderButton(file, index));
    }

    return renderButton(item, 0);
};
