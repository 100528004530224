import React from 'react';
// Import your JSON files
import en from './locales/en.json';
import et from './locales/et.json';
import { isDownloadableFile, renderDownloadLink } from './utils';

const translations = {
    en,
    et,
};

const getValue = (item) => {
    // Empty or invalid
    if (!item) return '';

    // If it's an array, map each element and join with commas
    if (Array.isArray(item)) {
        return item.map((subItem, index) => {
            const subValue = getValue(subItem);
            // Separate items with commas
            return (
                <React.Fragment key={index}>
                    {index > 0 && ', '}
                    {subValue}
                </React.Fragment>
            );
        });
    }

    // If it's an object
    if (typeof item === 'object') {
        if (item.value && typeof item.value === 'object') {
            if (isDownloadableFile(item)) {
                return (
                    <a href={item.value.url} target="_blank" rel="noopener noreferrer">
                        {item.value.name || item.value.url}
                    </a>
                );
            }
            if (item.value.firstName) {
                return getFullName(item);
            }
            return item.value.name || JSON.stringify(item.value);
        }
    }
    // if (typeof item === 'object') {
    //     // If the object has a `value` field which itself is an object
    //     if (item.value && typeof item.value === 'object') {
    //         // If we have a `url`, render a clickable link
    //         if (item.value.url) {
    //             return (
    //                 <a href={item.value.url} target="_blank" rel="noopener noreferrer">
    //                     {item.value.name || item.value.url}
    //                 </a>
    //             );
    //         }
    //         // Example: If there's a firstName, maybe return a full name
    //         if (item.value.firstName) {
    //             return getFullName(item); // your custom function
    //         }
    //         // Otherwise, show the object’s name or fallback
    //         return item.value.name || JSON.stringify(item.value);
    //     }
    // }

    // If it's a primitive (string/number)
    if (typeof item === 'string' || typeof item === 'number') {
        return item;
    }

    // Fallback
    return '';
};

const getFullName = (item) => {
    if (item && item.value) {
        return item.value.firstName + " " + item.value.lastName
    }
    else return "-"
}

const renderRow = (title, value) => (
    <tr>
        <td className="field-title">{title}</td>
        <td className="field-value">{value}</td>
    </tr>
);


const CategoryCard = ({ category, locale }) => {
    // Select translations based on `locale`; default to English
    const t = translations[locale] || translations.en;

    return (
        <div className="data-card">
            <table className="data-table">
                <tbody>
                    {renderRow(t.name, getValue(category.name))}
                    {renderRow(t.bio, getValue(category.bio))}
                    {renderRow(t.factory, getValue(category.factoryName))}
                    {category.group && renderRow(t.category + " - " + t.subcategories, getValue(category.group) + " - " + getValue(category.subGroup[0]?.value?.name))}
                    {renderRow(t.uniqueProperties, getValue(category.uniqueProperties))}
                    {renderRow(t.description, getValue(category.desc))}
                    {renderRow(t.owner, getValue(category.owner))}
                    {/* {renderRow(t.tags, getValue(category.tags))} */}
                    {/* {category.subGroup && renderRow(t.subcategories, getValue(category.subGroup))} */}

                    {renderRow(t.author, getValue(category.ipAuthor))}
                    {renderRow(t.usage, getValue(category.usage))}
                    {renderRow(t.factoryUrl, getValue(category.urlOnFactorySite))}
                    {category.featuresArray && (
                        <>
                            <tr>
                                <td colSpan="2">
                                    <strong>{t.features}</strong>
                                </td>
                            </tr>
                            {category.featuresArray.map((feature, index) =>
                                renderRow(
                                    // Fallback to "Features X" if there's no feature name
                                    feature.feature?.value?.name || `${t.features} ${index + 1}`,
                                    getValue(feature.featureValue)
                                )
                            )}
                        </>
                    )}
                    <>
                        <tr>
                            <td colSpan="2">
                                <strong>{t.references}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>{t.certificates}</td>
                            <td>{renderDownloadLink(category.certificates)}</td>
                        </tr>
                        <tr>
                            <td>{t.userManual}</td>
                            <td>{renderDownloadLink(category.installationManual)}</td>
                        </tr>
                        <tr>
                            <td>{t.maintenanceManual}</td>
                            <td>{renderDownloadLink(category.maintenanceManual)}</td>
                        </tr>
                    </>
                </tbody>
            </table>
        </div>
    );
};

export default CategoryCard;