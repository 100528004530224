import en from './locales/en.json';
import et from './locales/et.json';
import { renderDownloadLink } from './utils';

const translations = {
	en,
	et,
};

const ProductCard = ({ product, locale }) => {
	const placeholderImage = './image-placeholder.png'; // Path to your placeholder image
	const t = translations[locale] || translations.en;



	return (
		<div className="data-card">
			<table className="data-table">
				<tbody>
					{product.showImage && (
						<tr>
							<td colSpan="2">
								<img
									src={product.designImageSlider[0]?.designImage.url || placeholderImage}
									alt={product.name}
									className="data-image"
								/>
							</td>
						</tr>
					)}
					{product.category && (
						<tr>
							<td className="field-title">{t.collection}</td>
							<td className="field-value">{product.category.value.name}</td>
						</tr>
					)}
					{product.categoryDesc && (
						<tr>
							<td className="field-title">{t.categoryDesc}</td>
							<td className="field-value">{product.categoryDesc}</td>
						</tr>
					)}
					{product.categoryUsp && (
						<tr>
							<td className="field-title">{t.categoryUsp}</td>
							<td className="field-value">{product.categoryUsp}</td>
						</tr>
					)}
					<tr>
						<td className="field-title">{t.factory}</td>
						<td className="field-value">{product.factoryName}</td>
					</tr>
					<tr>
						<td className="field-title">{t.name}</td>
						<td className="field-value">{product.name}</td>
					</tr>
					<tr>
						<td className="field-title">{t.productId}</td>
						<td className="field-value">{product.externalId}</td>
					</tr>

					{product.subGroup && (
						<tr>
							<td className="field-title">{t.subcategory}</td>
							<td className="field-value">{product.subGroup.value.name}</td>
						</tr>
					)}

					<tr>
						<td className="field-title">{t.description}</td>
						<td className="field-value">{product.desc}</td>
					</tr>
					<tr>
						<td className="field-title">{t.productUsp}</td>
						<td className="field-value">{product.uniqueProperties}</td>
					</tr>
					{/* <tr>
						<td className="field-title">{t.tags}</td>
						<td className="field-value">{product.tags}</td>
					</tr> */}
					<tr>
						<td colSpan="2">
							<strong>{t.features}</strong>
						</td>
					</tr>
					{product.featuresArray.map((feature, index) => (
						<tr key={index}>
							<td className="field-title">{feature.feature?.value.name}</td>
							<td className="field-value">{feature.featureValue}</td>
						</tr>
					))}
					{product.category && (
						<>
							<tr>
								<td colSpan="2">
									<strong>{t.references}</strong>
								</td>
							</tr>
							<tr>
								<td>{t.certificates}</td>
								<td>{renderDownloadLink(product.category?.value?.certificates)}</td>
							</tr>
							<tr>
								<td>{t.userManual}</td>
								<td>{renderDownloadLink(product.category?.value?.installationManual)}</td>
							</tr>
							<tr>
								<td>{t.maintenanceManual}</td>
								<td>{renderDownloadLink(product.category?.value?.maintenanceManual)}</td>
							</tr>
						</>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default ProductCard;
